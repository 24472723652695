@font-face {
  font-family: 'Cabinet';
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Black.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Black.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Black.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Black.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 800;
}
@font-face {
  font-family: 'Cabinet';
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Bold.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Bold.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: bold;
}
@font-face {
  font-family: 'Cabinet';
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Extrabold.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Extrabold.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Extrabold.woff')
      format('woff'),
    /* Pretty Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Extrabold.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-weight: 900;
}
@font-face {
  font-family: 'Cabinet';
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Extralight.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Extralight.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Extralight.woff')
      format('woff'),
    /* Pretty Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Extralight.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-weight: 200;
}
@font-face {
  font-family: 'Cabinet';
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Light.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Light.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Light.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Light.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 300;
}
@font-face {
  font-family: 'Cabinet';
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Medium.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Medium.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Medium.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Medium.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-weight: 600;
}
@font-face {
  font-family: 'Cabinet';
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Regular.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Regular.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Regular.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-weight: 500;
}
@font-face {
  font-family: 'Cabinet';
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Thin.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Thin.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Thin.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Thin.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 400;
}
@font-face {
  font-family: 'Cabinet';
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Variable.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Variable.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Variable.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('./fonts/Fonts/WEB/fonts/CabinetGrotesk-Variable.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-weight: 100;
}

@font-face {
  font-family: 'Mobilla';
  src: url('./fonts/Mobilla-Regular.ttf') format('truetype');
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100dvh;
  /* overflow: auto; */
  /* min-height: 100dvh; */
  /* overflow: hidden; */
  /* height: 100dvh; */
  /* min-height: 100%; */
}

/* html {
} */
:root {
  --background-section-color: #fff3e2;
  --main-title-color: #4d4944;
  --simple-text-color: #33312d;
  --btn-color: #ff8b5e;
  --target-project-page-titles-and-p-color: #e6dbcb;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--main-title-color);
  opacity: 1; /* Firefox */
  font-family: 'Cabinet';
  font-weight: 300;
}
::placeholder:focus {
  color: white;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--main-title-color);
  font-family: 'Cabinet';
  font-weight: 300;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--main-title-color);
  font-family: 'Cabinet';
  font-weight: 300;
}
